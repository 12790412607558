import styled from 'styled-components'
import { media } from '../../../styles/breakpoints'

export const MainWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background: linear-gradient(#FCF2E8, #ECD5C3, #CDC8C2);
`

export const ComfortContentWrapper = styled.div`
  padding: 32px 21px;
  max-width: 1440px;

  ${media.tabMUp} {
    padding: 43px 158px 100px;
  }
`

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
`

export const CustomWrapper = styled(Wrapper)`
  @media only screen and (min-width: 1000px) {
    width: 80%;
    margin: 0 auto;
    justify-content: space-between;
  }
`

export const IconWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  text-align: center;
  margin: 0 5px;
  height: 120px;
  overflow: visible;

  ${media.tabMUp} {
    height: 150px;
  }

  img {
    width: 44px;
    height: 44px;

    ${media.tabMUp} {
      width: 86px;
      height: 86px;
    }
  }

  p {
    font-family: 'Proxima Nova', sans-serif;
    font-size: 11px;
    color: #222;
    font-weight: 700;
    max-width: 120px;

    ${media.tabMUp} {
      font-size: 20px;
      max-width: 225px;
    }
  }
`