import { useTranslation } from 'next-i18next'
import { LocaleNamespaces } from '../../../../public/static/locale-namespaces.enum'
import { translateHtml } from '../../../helpers/translateHtml'
import { WebpImage } from '../../atoms/Images'
import { SubscribeModalInput } from '../../atoms/SubscribeModalInput'
import LeadStore from '../../stores/LeadStore/LeadStore'
import { Background, Column, HowDoesWorkText, HowDoesWorkTitle, ImageWrapper, TextWrapper } from './HowDoesWorkStyles'

const leadStore = new LeadStore()

export const HowDoesWork = (props: { onClickButton: Function }) => {
  const { t: translate } = useTranslation(LocaleNamespaces.INTERNATIONAL_LANDING_PAGE)
  const translatePrivacyHtml = (key: string) => translateHtml(key, 'international-landing-page')

  return (
    <Background>
      <Column data-testid="main-column">
        <TextWrapper data-testid="text-wrapper">
          <HowDoesWorkTitle>{translate('how-does-work-title')}</HowDoesWorkTitle>
          <HowDoesWorkText>
            <p dangerouslySetInnerHTML={translatePrivacyHtml('how-does-work-text')} className="main-text" />
            <p dangerouslySetInnerHTML={translatePrivacyHtml('how-does-work-coach')} className="coach" />
            <SubscribeModalInput
              store={leadStore}
              inputFieldClassName="input-field"
              inputButtonClassName="input-button"
              inputWrapperClassName="input-wrapper"
              inputErrorClassName="input-error"
              openModalAfterSubscribe={props.onClickButton}
            />
          </HowDoesWorkText>

        </TextWrapper>
        <ImageWrapper data-testid="image-wrapper">
          <WebpImage
            src={`/images/buy_page_img8.png`}
            srcSet={`/images/buy_page_img8.webp`}
            alt="How does it work"
            loadingStrategy="lazy"
          />
        </ImageWrapper>
      </Column>
    </Background>
  )
}