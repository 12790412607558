import { useTranslation } from 'next-i18next'
import React from 'react'
import { LocaleNamespaces } from '../../../../public/static/locale-namespaces.enum'
import { translateHtml } from '../../../helpers/translateHtml'
import { LandingPageDropdowItem } from '../../atoms/LandingPageDropdown'
import { DropdownMainTitle, DropdownWrapper } from './InternationalDropdownStyles'

export const InternationalDropdown = () => {
  const { t: translate } = useTranslation(LocaleNamespaces.INTERNATIONAL_LANDING_PAGE)
  const translatePrivacyHtml = (key: string) => translateHtml(key, LocaleNamespaces.INTERNATIONAL_LANDING_PAGE)

  const questions = [
    translatePrivacyHtml('international-dropdown-question-1').__html,
    translatePrivacyHtml('international-dropdown-question-2').__html,
    translatePrivacyHtml('international-dropdown-question-3').__html,
    translatePrivacyHtml('international-dropdown-question-4').__html,
    translatePrivacyHtml('international-dropdown-question-5').__html,
    translatePrivacyHtml('international-dropdown-question-6').__html,
    translatePrivacyHtml('international-dropdown-question-7').__html,
  ]
  const answers = [
    translatePrivacyHtml('international-dropdown-answer-1').__html,
    translatePrivacyHtml('international-dropdown-answer-2').__html,
    translatePrivacyHtml('international-dropdown-answer-3').__html,
    translatePrivacyHtml('international-dropdown-answer-4').__html,
    translatePrivacyHtml('international-dropdown-answer-5').__html,
    translatePrivacyHtml('international-dropdown-answer-6').__html,
    translatePrivacyHtml('international-dropdown-answer-7').__html,
  ]

  return (
    <DropdownWrapper>
      <DropdownMainTitle>
        {translate('international-dropdown-title')}
      </DropdownMainTitle>
      {
        questions.map((question, index) => (
          <LandingPageDropdowItem
            title={question}
            content={answers[index]}
            key={index}
          />
        ))
      }
    </DropdownWrapper>
  )
}