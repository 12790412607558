import { IPostMeta } from '../../../services/interfaces/WordpressServiceDtos'

export const internationalLandingPageMeta: IPostMeta[] = [
  { property: 'og:title', content: 'Persono Your Perfect Smart Pillow' },
  { property: 'twitter:title', content: 'Persono Your Perfect Smart Pillow' },
  { property: 'og:description', content: 'Better sleep starts with understanding it: Track your sleep, learn about your sleep habits and reach your sleep goals with a Persono Smart Pillow' },
  { property: 'og:image:alt', content: 'Persono Your Perfect Smart Pillow' },
  { name: 'description', content: 'Better sleep starts with understanding it: Track your sleep, learn about your sleep habits and reach your sleep goals with a Persono Smart Pillow' },
  { property: 'og:image', content: 'https://persono.com.br/images/ogp/home.jpg' },
  { property: 'og:image:secure_url', content: 'https://persono.com.br/images/ogp/home.jpg' },
  { property: 'og:image:type', content: 'image/jpeg' },
  { property: 'og:image:width', content: '713' },
  { property: 'og:image:height', content: '1000' },
]