import { captureException } from '@sentry/nextjs'

import { Analytics, logEvent } from 'firebase/analytics'
import { initGoogleAnalytics } from './FirebaseService'

export enum AnalyticsEventsKeys {
  LPI_CLICK_ON_MODAL= 'LPIClickOnModal'
}

export const logAnalyticsEvent = async (key: string) => {
  try{
    const analytics: Analytics = initGoogleAnalytics()
    logEvent(analytics, key)
  } catch (e) {
    captureException(e)
  }
}