import { LPGradient, LPSection } from './styles'

export interface ILPSectionProps {
  sectionBackground?: string,
  gradientBackground?: string,
  height?: number,
  children: React.ReactNode,
}

export const LandingPageSection = (props: ILPSectionProps) => {
  const { sectionBackground, gradientBackground, children, height } = props

  return (
    <LPSection background={sectionBackground} height={height} data-testid="landing-page-section">
      <LPGradient background={gradientBackground} height={height} data-testid="landing-page-gradient">
        {children}
      </LPGradient>
    </LPSection>
  )
}