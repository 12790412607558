import { translateHtml } from '../../../helpers/translateHtml'
import { Container, ImageWrapper, TextWrapper } from './OurSmartPillowStyles'

export const OurSmartPillow = (props: {canUseWebP: boolean}) => {
  const translatePrivacyHtml = (key: string) => translateHtml(key, 'international-landing-page')
  
  return (
    <Container data-testid="main-column" >
      <TextWrapper data-testid="text-wrapper">
        <p dangerouslySetInnerHTML={translatePrivacyHtml('our-smart-pillow-text')} />
      </TextWrapper>
      <ImageWrapper 
        backgroundUrl={`/images/buy_page_img6.${props.canUseWebP ? 'webp' : 'png'}`}
        data-testid="image-wrapper" 
      />
    </Container>
  )
}