import styled from 'styled-components'
import { media } from '../../../styles/breakpoints'

export const LineSpacer = styled.div`
  min-width: 100%;
  min-height: 10px;
  margin-bottom: -10px;
  background: linear-gradient(rgba(0,0,0, 0.5) 0%, #D6D1CB 100%);
  filter: opacity(0.8) blur(2px);

  ${media.tabMUp} {
    background: linear-gradient(rgba(0,0,0, 0.3) 0%, #D6D1CB 100%);
  }
`

export const WhiteLine = styled.div`
  min-width: 100%;
  min-height: 2px;
  background-color: #fff; 

  ${media.tabMUp} {
    min-height: 1px;
  }
`