import styled from 'styled-components'
import { media } from '../../../styles/breakpoints'

export const Spacer = styled.div<{desktopHeight: string, mobileHeight: string}>`
  width: 0.1px;
  height: ${({mobileHeight}) => mobileHeight};

  ${media.tabMUp} {
    height: ${({desktopHeight}) => desktopHeight};
  }
`