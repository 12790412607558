import { useRef, useState } from 'react'
import { DropdownIconBlue } from '../icons/DropdownIconBlue'
import { ContentContainer, ContentTitle, DropdownContainer, InfoContainer, InfoText, TitleContainer } from './styles'

interface IFaqContentinfos {
  title: string
  content: string
}

export const LandingPageDropdowItem = ({ title, content }: IFaqContentinfos) => {
  const itemRef = useRef(null)
  const [active, setActive] = useState(false)
  const [height, setHeight] = useState(0)
  const toggleActive = () => setActive(!active)
  const setBodyHeight = (value: number) => setHeight(value)

  return (
    <ContentContainer onClick={() => {
      toggleActive()
      setBodyHeight(itemRef.current.clientHeight + 20)
    }}
      id="main-container">
      <TitleContainer>
        <ContentTitle>
          {title}
        </ContentTitle>
        <DropdownContainer active={active}>
          <DropdownIconBlue />
        </DropdownContainer>
      </TitleContainer>
      <InfoContainer active={active} bodyHeight={height}>
        <InfoText dangerouslySetInnerHTML={{
          __html: content
        }}
          ref={itemRef}
          id="info-text"
        />
      </InfoContainer>
    </ContentContainer>
  )
}