import styled from 'styled-components'
import { media } from '../../../styles/breakpoints'

export const SliderWrapper = styled.div`
  overflow: hidden;
  z-index: 1;
  display: flex;
  
  * {
    z-index: 1;
  }

  .carousel {
    width: 100vw;
    
    ${media.tabMUp} {
      width: 100%;
      max-width: 1440px;
    }

    ${media.deskGUp} {
      min-height: 620px;
      max-height: 620px;
    }

    @media only screen and (min-width: 1440px) {
      min-height: 740px;
      max-height: 740px;
    }

    .swiper-button-prev, .swiper-button-next {
      visibility: hidden;
      display: none;
      max-width: 0;
      max-height: 0;

      ${media.deskGUp} {
        visibility: visible;
        display: unset;
        color: #FFF;
        margin: 0 50px;
      }
    }

    .swiper-button-next {
      ${media.deskGUp} {        
        padding: 0 15px;
      }
    }

    .swiper-pagination {
      display: flex !important;
      top: 0 !important;
      left: calc(50% - 40px) !important;
      max-width: 80px !important;
      height: 20px !important;
      margin: 0 auto !important;
      border-radius: 70px !important;
      justify-content: center !important;
      align-items: center !important;
      border: 1px solid #B0B0B0 !important;
      background: transparent !important;

      ${media.tabMUp} {
        top: unset !important;
        bottom: 30px !important;
        max-width: 120px !important;
        height: 28px !important;
        left: 45% !important;
        background: rgba(0,0,0, 0.3) !important;
        border-radius: 86px !important;
        border: none !important;
        margin-left: 35px !important;
      }

      @media screen and (min-width: 870px){
        margin: 0 !important;
        height: 36px !important;
        max-width: 155px !important;
      }

      ${media.deskGUp} {
        bottom: 70px !important;
      }
    }

    .swiper-pagination-bullet {
      min-width: 15px !important;
      height: 5px !important;
      background-color: transparent !important;
      transition: all 200ms ease-in-out !important;
      
      ${media.tabMUp} {
        width: 10px !important;
        height: 10px !important;
        background-color: rgba(219, 219, 219, 1) !important;
        margin: 0 10px !important;
      }
    }

    .swiper-pagination-bullet-active {
      min-width: 15px !important;
      height: 5px !important;
      border-radius: 40px !important;
      background-color: #FFF !important;
      transition: all 300ms ease-in-out !important;

      ${media.tabMUp} {
        width: 50px !important;
        height: 10px !important;
      }
    }
  }
`

export const SliderPage = styled.div`
  min-height: 460px;
  max-height: 460px;
  min-width: 280px;
  margin-top: 60px;

  ${media.tabMUp} {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    margin: 0 auto;
    max-width: 80%;
  }

  ${media.deskGUp} {
    min-height: 620px;
    max-height: 620px;
  }

  @media only screen and (min-width: 1440px) {
    min-height: 740px;
    max-height: 740px;
    max-width: 1200px;
  }
`

export const ImageWrapper = styled.div<{ currentSlide: boolean }>`
  transition: all 200ms ease-in-out;
  margin-top: ${p => p.currentSlide ? '0' : '60'}px;
  width: 100%;

  @media screen and (min-width: 525px) {
    min-height: 490px;
    max-height: 490px;
  }

  ${media.tabMUp} {
    min-height: 100%;
    display: flex;
    justify-content: flex-end;
    padding-right: 30px;
  }

  ${media.deskGUp} {
    min-height: 620px;
    max-height: 620px;
    width: 60vw;
    overflow: visible;
    padding: 0;
    margin: 0 10px;
  }

  @media only screen and (min-width: 1440px) {
    min-height: 740px;
    max-height: 740px;
  }

  img {
    min-height: 380px;
    max-height: 380px;
    transition: all 200ms ease-in-out;
    object-fit: cover;
    width: 100%;

    @media screen and (min-width: 425px) {
      object-fit: contain;
    }

    ${media.tabMUp} {
      object-fit: fill;
      object-position: 0 10px;
      width: max-content;
      max-width: 420px;
      min-height: 100%;
    }

    ${media.deskGUp} {
      object-position: 10px 10px;
      object-fit: cover;
      max-width: 440px;
      min-height: 100%;
      padding: 0;
      margin: 0 20px;
    }

    @media only screen and (min-width: 1440px) {
      min-height: 740px;
      max-height: 740px;
      object-position: -100px 10px;
      max-width: 500px;
      margin: 0;
    }
  }
`

export const BoldSliderText = styled.span<{ visible?: boolean }>`
  font-weight: 700;
  margin-right: 5px;

  ${media.tabMUp} {
    display: ${props => props.visible ? '' : 'none'};
  }
`

export const SliderText = styled.p<{ currentSlide: boolean }>`
  text-align: center;
  height: 100px;
  overflow: hidden;
  color: #222;
  font-family: 'Proxima Nova', sans-serif;
  font-size: 14px;
  font-weight: 400;
  transition: all 200ms ease-in-out;
  opacity: ${p => p.currentSlide ? '1' : '0'};

  ${media.tabMUp} {
    font-size: 24px;
    text-align: left;
    height: max-content;
    width: max-content;
    max-width: 40vw;
  }
  
  ${media.deskGG} {
    font-size: 30px;
  }
  
  @media only screen and (min-width: 1440px) {
    font-size: 36px;
    max-width: 600px;
  }
`

export const SliderTitle = styled.h3`
  color: #4E98F1;
  text-align: center;
  font-size: 21px;
  font-family: 'Proxima Nova', sans-serif;
  font-weight: 700;
  line-height: 27px;
  padding: 30px 30px 30px;
  margin: 0;
   
  ${media.tabMUp} {
    font-size: 38px;
    line-height: 120%;
    text-align: left;
    width: max-content;
    height: max-content;
    max-width: 45vw;
    padding: 0;
  }
  
  ${media.deskGUp} {
    font-size: 42px;
  }
  
  ${media.deskGG} {
    font-size: 50px;
  }

  @media only screen and (min-width: 1440px) {
    font-size: 66px;
    max-width: 670px;
  }
`

export const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
`