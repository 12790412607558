import React from 'react'
import { Theme } from '../../../styles/theme-enum'
import { IResponsiveValues } from '../Button'
import { InternationalH2, InternationalP, InternationalTestimonial, InternationalTestimonialAuthor } from './styles'

interface ITextWidgetsProps {
  theme: Theme,
  children: React.ReactNode,
  textAlign?: IResponsiveValues,
  boldText?: boolean,
  dataTestId?: string,
}

export const LandingPageHeading = (props: ITextWidgetsProps) => {
  const { theme, children, textAlign, boldText, dataTestId } = props
  const headingProps = {
    desktopTextAlign: textAlign?.desktopValue ?? '',
    mobileTextAlign: textAlign?.mobileValue ?? '',
    boldText: boldText ?? true
  }

  return (
    <InternationalH2
      data-testid={dataTestId}
      theme={theme}
      mobileTextAlign={headingProps.mobileTextAlign}
      desktopTextAlign={headingProps.desktopTextAlign}
      boldText={headingProps.boldText}
    >
      {children}
    </InternationalH2>
  )
}

export const LandingPageParagraph = (props: ITextWidgetsProps) => {
  const { theme, children, textAlign, boldText } = props
  const paragraphProps = {
    desktopTextAlign: textAlign?.desktopValue ?? '',
    mobileTextAlign: textAlign?.mobileValue ?? '',
    boldText: boldText ?? false,
  }

  return(
    <InternationalP 
      theme={theme}
      mobileTextAlign={paragraphProps.mobileTextAlign}
      desktopTextAlign={paragraphProps.desktopTextAlign}
      boldText={paragraphProps.boldText}
    >
      {children}
    </InternationalP>
  )
}

export const LandingPageTestimonial = (props: ITextWidgetsProps) => {
  const { theme, children, textAlign, boldText } = props
  const paragraphProps = {
    desktopTextAlign: textAlign?.desktopValue ?? '',
    mobileTextAlign: textAlign?.mobileValue ?? '',
    boldText: boldText ?? false,
  }

  return(
    <InternationalTestimonial 
      theme={theme}
      mobileTextAlign={paragraphProps.mobileTextAlign}
      desktopTextAlign={paragraphProps.desktopTextAlign}
    >
      {children}
    </InternationalTestimonial>
  )
}

export const LandingPageTestimonialAuthor = (props: ITextWidgetsProps) => {
  const { theme, children, textAlign, boldText } = props
  const paragraphProps = {
    desktopTextAlign: textAlign?.desktopValue ?? '',
    mobileTextAlign: textAlign?.mobileValue ?? '',
    boldText: boldText ?? false,
  }

  return(
    <InternationalTestimonialAuthor 
      theme={theme}
      mobileTextAlign={paragraphProps.mobileTextAlign}
      desktopTextAlign={paragraphProps.desktopTextAlign}
    >
      {children}
    </InternationalTestimonialAuthor>
  )
}