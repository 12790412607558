import { useTranslation } from 'next-i18next'
import { LocaleNamespaces } from '../../../../public/static/locale-namespaces.enum'
import { SubscribeModalInput } from '../../atoms/SubscribeModalInput'
import LeadStore from '../../stores/LeadStore/LeadStore'
import { Container, MainWrapper, Wrapper } from './LandingPageMainContentStyles'

const leadStore = new LeadStore()

export const MainContent = (props: { onClickButton: Function, isMobile: boolean }) => {
  const { t: translate } = useTranslation(LocaleNamespaces.INTERNATIONAL_LANDING_PAGE)
  const { onClickButton, isMobile } = props

  return (
    <MainWrapper>
      <video
        autoPlay
        muted
        playsInline
        loop
        className="lpi-video"
      >
        <source src={`/images/lpi-video${isMobile ? '' : '-desktop'}.webm`} type="video/webm" />
        <source src={`/images/lpi-video${isMobile ? '' : '-desktop'}.mp4`} type="video/mp4" />
      </video>

      <Container>
        <h1 data-testid="main-title">{translate('main-content-title')}</h1>
        <Wrapper>
          <p data-testid="main-text">{translate('main-content-text')}</p>
          <SubscribeModalInput
            store={leadStore}
            inputFieldClassName="input-field"
            inputButtonClassName="input-button"
            inputWrapperClassName="input-wrapper"
            inputErrorClassName="input-error"
            openModalAfterSubscribe={onClickButton}
          />
        </Wrapper>
      </Container>
    </MainWrapper>
  )
}