import styled from 'styled-components'
import { media } from '../../../styles/breakpoints'

export const MainWrapper = styled.div`
  background: linear-gradient(180deg, black 0%, #030C0D 76%, #04151A 83%, #02171D 88%, #052227 96%, #042127 100%);
  width: 100%;
  padding: 27px 13px;
  margin-bottom: 30px;

  ${media.tabMUp} {
    padding: 66px 33px;
  }

  ${media.deskG} {
    background: linear-gradient(180deg, #000 0%, #030C0D 76.09%, #04151A 83.18%, #02171D 88.13%, #052227 95.54%, #042127 100%);
    margin-bottom: -20px;
    padding: 66px 33px 112px;
  }
  
  ${media.deskGG} {
    margin-bottom: -20px;
    padding: 105px 53px 198px;
  }
  
  .input-wrapper {
    margin: 0 auto;
    padding: 0;
    max-width: 215px;
    
    ${media.tabMUp} {
      margin: 50px auto 0;
      max-width: 465px;
    }
  }

  .input-button {
    height: 26px;

    ${media.tabMUp} {
      height: 46px;
    }
  }
  .input-field {
    height: 26px;
    margin: 0;

    ${media.tabMUp} {
      height: 46px;
    }
  }
`

export const MainTitle = styled.h3`
  color: #4E98F1;
  font-size: 31px;
  font-weight: 700;
  line-height: 110%;
  text-align: center;
  width: 100%;
  padding: 0;
  margin: 0;

  ${media.tabMUp} {
    font-size: 74px;
  }

  ${media.deskGG} {
    font-size: 118px;
  }
`

export const Text = styled.p`
  color: #f7f7f7;
  font-size: 17px;
  font-weight: 400;
  line-height: 110%;
  width: 100%;
  text-align: center;
  margin: 7px 0;

  ${media.tabMUp} {
    font-size: 41px;
    margin-top: 31px;
  }

  ${media.deskGG} {
    font-size: 66px;
  }
`

export const ComparatveMainWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  padding: 70px 0;
  justify-content: center;
  align-items: center;
`

export const ComparativeItem = styled.div<{img: string}>`
  background: url(${props => props.img});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  min-width: 124px;
  max-width: 124px;
  min-height: 124px;
  max-height: 124px;
  margin: 0 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  ${media.mobSUp} {
    min-width: 130px;
    max-width: 130px;
    min-height: 130px;
    max-height: 130px;
  }

  ${media.tabMUp} {
    min-width: 322px;
    max-width: 322px;
    min-height: 322px;
    max-height: 322px;
  }

  ${media.deskGG} {
    min-width: 514px;
    max-width: 514px;
    min-height: 514px;
    max-height: 514px;
  }
`

export const ComparativeItemRight = styled(ComparativeItem)`
  background-size: 125%;
  background-position: 50% 52%;
  margin: 0; 
`

export const ComparativeBorder = styled.div`
  max-height: 1px;
  background-color: #4E98F1;
  position: relative;
  
  ${media.tabMUp} {
    max-height: 2px;
  }

  .text {
    color: #4E98F1;
    text-align: center;
    font-size: 13px;
    font-weight: 700;

    ${media.tabMUp} {
      font-size: 31px;
    }

    ${media.deskGG} {
      font-size: 50px;
    }
  }

  .border {
    max-height: 3px;
    transform: translateY(-1px);
    border-left: 1px solid #4E98F1;
    border-right: 1px solid #4E98F1;
    
    ${media.tabMUp} {
      max-height: 5px;
      border-left: 2px solid #4E98F1;
      border-right: 2px solid #4E98F1;
      transform: translateY(-1px);
    }
  }
`

export const ComparativeBorderBottom = styled(ComparativeBorder)`
  width: 70%;
  transform: translateY(65px);

  ${media.tabMUp} {
    transform: translateY(161px);
  }

  ${media.deskGG} {
    transform: translateY(257px);
  }

  .text {
    transform: translateY(2px);
  }
`

export const ComparativeBorderLeft = styled(ComparativeBorder)`
  width: 70%;
  transform: rotate(270deg) translateY(-57px);

  ${media.tabMUp} {
    transform: rotate(270deg) translateY(-140px);
  }

  ${media.deskGG} {
    transform: rotate(270deg) translateY(-220px);
  }

  .text {
    transform: translateY(-20px);

    ${media.tabMUp} {
      transform: translateY(-38px);
    }

    ${media.deskGG} {
      transform: translateY(-57px);
    }
  }
`

export const ComparativeBorderRight = styled(ComparativeBorder)`
  width: 80%;
  transform: rotate(90deg) translateY(-57px);

  ${media.tabMUp} {
    transform: rotate(90deg) translateY(-130px);
  }

  ${media.deskGG} {
    transform: rotate(90deg) translateY(-200px);
  }

  .text {
    transform: translateY(-20px);
    
    ${media.tabMUp} {
      transform: translateY(-38px);
    }

    ${media.deskGG} {
      transform: translateY(-57px);
    }
  }
`
