import { WebpImage } from '../../atoms/Images'
import { Column, ImageContainer, TestimonialWrapper } from './InternationalTestimonialStyles'


export interface ILPTestimonilaProps {
  name: string,
  speech: string,
  img: string,
  occupation: string,
  isUnique: boolean,
  customMargin: boolean
}

export const InternationalTestimonial = (props: ILPTestimonilaProps) => {
  const { name, speech, img, occupation, isUnique, customMargin } = props

  return (
    <TestimonialWrapper data-testid="testimonial-wrapper">
      <ImageContainer data-testid="image-container" isUnique={isUnique}>
        <WebpImage
          src={`/images/${img}.png`}
          srcSet={`/images/${img}.webp`}
          alt={name}
          loadingStrategy="lazy"
        />
      </ImageContainer>

      <Column 
        data-testid="testimonial-column" 
      >
        <p className="mainText">
          <q>{speech}</q>
        </p>
        <p className="customerText">
          <span>- {name}</span>, {occupation}
        </p>
      </Column>
    </TestimonialWrapper>
  )
}