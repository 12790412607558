import styled from 'styled-components'
import { media } from '../../../styles/breakpoints'

export const Column = styled.div`
  width: 100%;
  background-color: #fff;
  display: flex;
  justify-content: center;
`

export const Wrapper = styled.div`
  max-width: 1440px;
  display: grid;
  grid-template-columns: 1fr;
  padding: 32px 29px;

  @media screen and (min-width: 950px) {
    grid-template-columns: 1fr 1fr;
    grid-row-gap: 20px;
    grid-column-gap: 50px;
    padding: 52px 59px;
  }
`

export const TestimonialItem = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  max-width: 80vw;
  margin: 0;
  height: 170px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  
  ${media.tabMUp} {
    max-width: 70vw;
    height: 400px;
  }

  @media screen and (min-width: 1024px) {
    max-width: 60vw;
    height: 550px;
  }
`

export const SliderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  height: 200px;

  ${media.tabMUp} {
    width: 100%;
    margin: 0;
    padding: 0 20px;
    height: 400px;
  }
  
  @media screen and (min-width: 1024px) {
    height: 550px;
    padding: 0 5vw;
  }

  .carousel {
    .swiper-button-prev, .swiper-button-next {
      visibility: hidden !important;

      ${media.tabMUp} {
        visibility: visible !important;
      }
    }

    .swiper-pagination {
      display: flex;
      justify-content: center;
      bottom: 5px;

      ${media.tabMUp} {
        display: none;
      }
    }
  }
`

export const SliderPageWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 0;
`