import styled, { css } from 'styled-components'
import { media } from '../../../styles/breakpoints'

export const ContentContainer = styled.div`
  width: 80%;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 auto;
  background-color: transparent;
  min-width: 300px;
  border-bottom: 1px solid rgba(217, 217, 217, 0.60);
  padding: 4px 19px 4px 15px;
  
  &:last-of-type {
    border: none;
  }
  
  ${media.tabMUp} {
    border-bottom: 2px solid rgba(217, 217, 217, 0.60);
    max-width: 1092px;
    padding: 8px 63px 8px 46px;
  }
`

export const ContentTitle = styled.h2`
  line-height: 135%;
  max-width: calc(100% - 50px);
  font-weight: 400;
  margin: 10px 0;
  color: #4E98F1;
  font-size: 16px;
  font-family: 'Proxima Nova', sans-serif;

  ${media.tabMUp} {
    max-width: calc(100% - 200px);
    font-size: 24px;
  }
`

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  background-color: #F7F7F7;
`

export const InfoContainer = styled.div<{ active: boolean, bodyHeight: number }>`
  margin: 0;
  width: 100%;
  color: #f7f7f7;
  height: 0;
  overflow: hidden;
  transition: height 0.3s;
  padding-left: 20px;
  padding-right: 40px;
  transition: all 200ms ease-in-out;
  
  ${({ active, bodyHeight }) =>
    active &&
    css`
      height: ${bodyHeight + 10}px;

      ${media.tabMUp} {
        height: ${bodyHeight + 10}px;
      }
    `}
`

export const InfoText = styled.p`
  transition: 200ms ease-in-out;
  overflow: hidden;
  color: #424242;
  line-height: 150%;
  font-size: 12px;
  font-family: 'Proxima Nova', sans-serif;

  ${media.tabMUp} {
    font-size: 16px;
  }
`

export const DropdownContainer = styled.div<{ active: boolean }>`
  width: 16px;
  height: 16px;
  transition: all 100ms ease;
  transform: ${props => props.active ? 'rotate(180deg)' : 'rotate(0deg)'};

  & > * {
    transform: scale(0.8);

    ${media.tabMUp} {
      transform: scale(1);
    }
  }
`