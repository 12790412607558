interface IAppInfosSliderData {
  image: string,
  title: string,
  infoBold: string,
  info: string,
}

export const appInfosSliderData: IAppInfosSliderData[] = [
  {
    image: '/images/slider-img-0',
    title: 'app-infos-slider-0-title',
    info: 'app-infos-slider-0-info',
    infoBold: 'app-infos-slider-0-info-bold',
  },
  {
    image: '/images/slider-img-1',
    title: 'app-infos-slider-1-title',
    info: 'app-infos-slider-1-info',
    infoBold: 'app-infos-slider-1-info-bold',
  },
  {
    image: '/images/slider-img-2',
    title: 'app-infos-slider-2-title',
    info: 'app-infos-slider-2-info',
    infoBold: 'app-infos-slider-2-info-bold',
  },
]
