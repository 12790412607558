import { useTranslation } from 'next-i18next'
import { useEffect, useState } from 'react'
import { LocaleNamespaces } from '../../../../public/static/locale-namespaces.enum'
import { AnalyticsEventsKeys, logAnalyticsEvent } from '../../../services/firebase/Analytics'
import { canUseWebP } from '../../../utils/checkWebpSuport'
import { AppInfosSliderDesktop, AppInfosSliderMobile } from '../../molecules/InternationalLandingPage/AppInfosSlider'
import { AppSteps } from '../../molecules/InternationalLandingPage/AppSteps'
import { CallToAction } from '../../molecules/InternationalLandingPage/CallToAction'
import { ComfortContent } from '../../molecules/InternationalLandingPage/ComfortContent'
import { ComparativeCard } from '../../molecules/InternationalLandingPage/ComparativeCards'
import { HowDoesWork } from '../../molecules/InternationalLandingPage/HowDoesWork'
import { ImageBox } from '../../molecules/InternationalLandingPage/ImageBox'
import { InternationalDropdown } from '../../molecules/InternationalLandingPage/InternationalDropdown'
import { MainContent } from '../../molecules/InternationalLandingPage/LandingPageMainContent'
import { LineSpacer, WhiteLine } from '../../molecules/InternationalLandingPage/LineSpacer'
import { OurSmartPillow } from '../../molecules/InternationalLandingPage/OurSmartPillow'
import { PersonoSizeComparative } from '../../molecules/InternationalLandingPage/PersonoSizeComparative'
import { SubscribeModal } from '../../molecules/InternationalLandingPage/SubscribeModal'
import { TestimonialSlider } from '../../molecules/InternationalLandingPage/TestimonialSlider'
import { LandingPageSection } from '../../organisms/LandingPageSection'
import { BaseEn } from '../../templates/BaseEn'
import { internationalLandingPageMeta } from './meta'

export const InternationalLandingPage = () => {
  const { t: translate } = useTranslation(LocaleNamespaces.INTERNATIONAL_LANDING_PAGE)
  const [isMobile, setIsMobile] = useState<boolean>(true)
  const [isModalOpened, setIsModalOpened] = useState(false)
  const [successSendEmail, setSuccessSendEmail] = useState(false)

  const handleWindowWidth = () => {
    setIsMobile(window.innerWidth < 768)
  }

  const toggleModal = () => {
    if (!isModalOpened) {
      logAnalyticsEvent(AnalyticsEventsKeys.LPI_CLICK_ON_MODAL)
    }
    setIsModalOpened(!isModalOpened)
  }

  const closeModal = () => {
    setIsModalOpened(false)
    setSuccessSendEmail(false)
  }

  const openModalAfterSucessSendEmail = () => {
    setIsModalOpened(true)
    setSuccessSendEmail(true)
  }

  useEffect(() => {
    window.addEventListener('resize', handleWindowWidth)
    handleWindowWidth()
  }, [])

  return (
    <BaseEn title={translate('browser-tab-title')} toggleModal={toggleModal} meta={internationalLandingPageMeta}>

      {
        isModalOpened && <SubscribeModal
          onCloseModal={closeModal}
          successSendEmail={successSendEmail}
        />
      }

      <LandingPageSection>
        <MainContent onClickButton={openModalAfterSucessSendEmail} isMobile={isMobile} />
      </LandingPageSection>

      <LandingPageSection sectionBackground="#F0ECE3">
        <ComfortContent canUseWebP={canUseWebP()} isMobile={isMobile} />
      </LandingPageSection>

      <LandingPageSection sectionBackground="#F0ECE3">
        <OurSmartPillow canUseWebP={canUseWebP()} />
        <ImageBox isMobile={isMobile}/>
        <HowDoesWork
          onClickButton={openModalAfterSucessSendEmail}
        />
      </LandingPageSection>

      <LandingPageSection
        sectionBackground={
          isMobile
            ? 'linear-gradient(90deg, #FCF2E8 0%, #FCF3E8 24.85%, #F3E6DD 55.31%, #E7DED5 70.84%, #DBD4CE 86.74%, #CDC8C2 100%);'
            : 'linear-gradient(90deg, #F3E7DE 0%, #E2D9D2 70.84%, #D6D1CB 100%);'
        }
      >
        {isMobile
          ? <AppInfosSliderMobile />
          : <AppInfosSliderDesktop />
        }

      </LandingPageSection>

      <LandingPageSection
        sectionBackground="linear-gradient(90deg, #FCF2E8 0%, #FCF3E8 24.85%, #F3E6DD 55.31%, #E7DED5 70.84%, #DBD4CE 86.74%, #CDC8C2 100%);"
      >
        <WhiteLine />
        <LineSpacer />
        <ComparativeCard />
        <PersonoSizeComparative
          onClickButton={openModalAfterSucessSendEmail}
          isMobile={isMobile}
        />
        <AppSteps isMobile={isMobile} />
        <TestimonialSlider />
      </LandingPageSection>

      <LandingPageSection sectionBackground="#f7f7f7">
        <CallToAction
          onClickButton={openModalAfterSucessSendEmail}
        />
        <InternationalDropdown />
      </LandingPageSection>

    </BaseEn>
  )
}