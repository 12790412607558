import React from 'react'
import { WebpImage } from '../../atoms/Images'
import { ImageWrapper } from './WomanOnTheBeachStyles'

export const WomanOnTheBeach = (props: {isMobile: boolean}) => {
  const imgSrc = `/images/buy_page_woman_beach${props.isMobile ? '-mobile' : ''}`
  
  return (
    <>
      <ImageWrapper data-testid="image-container">
        <WebpImage 
          src={`${imgSrc}.png`}
          srcSet={`${imgSrc}.webp`}
          alt="woman relaxing on the beach"
          loadingStrategy="lazy"
        />
      </ImageWrapper>
    </>
  )
}