import styled from 'styled-components'
import { media } from '../../../styles/breakpoints'

export const MainWrapper = styled.div`
  display: flex;
  justify-content: center;

  .lpi-video {
    width: 100vw;
    max-height: 460px;
    min-height: 460px;
    object-fit: cover;
    position: absolute;
    z-index: 1;

    @media screen and (min-width: 335px) {
      max-height: 431px;
      min-height: 431px;
    }
    
    @media screen and (min-width: 365px) {
      max-height: 411px;
      min-height: 411px;
    }
    
    @media screen and (min-width: 386px) {
      max-height: 382px;
      min-height: 382px;
    }

    @media screen and (min-width: 533px) {
      min-height: 362px; 
      max-height: 362px; 
    }
    
    @media screen and (min-width: 717px) {
      min-height: 333px; 
      max-height: 333px; 
    }
    
    @media screen and (min-width: 768px) {
      min-height: 988px; 
      max-height: 988px; 
    }
    
    @media screen and (min-width: 1030px) {
      min-height: 904px; 
      max-height: 904px; 
    }
    
    @media screen and (min-width: 1173px) {
      min-height: 820px; 
      max-height: 820px; 
    }
  }
`

export const Container = styled.div`
  z-index: 2;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 21px 15px 12px;
  width: 100%;
  min-height: 100%;

  ${media.tabMUp} {
    background: initial;
    padding: 50px 0 131px 97px;
    justify-content: center;
    align-items: initial;
  }

  h1 {
    font-family: 'Proxima Nova', sans-serif;
    color: #f7f7f7;
    font-weight: 700;
    font-size: 25px;
    text-align: center;
    margin-bottom: 110px;

    ${media.tabMUp} {
      max-width: 1160px;
      font-size: 70px;
      padding: 0;
      margin: 5px 80px 27px 0;
      line-height: 1.2;
      text-align: start;
    }
  }

  p {
    font-family: 'Proxima Nova', sans-serif;
    color: #f7f7f7;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    margin: 0;
    padding: 0;

    ${media.tabMUp} {
      font-size: 27px;
      font-weight: 500;
      text-align: left;
      line-height: 36px;
      margin-bottom: 50px;
      max-width: 380px;
    }
  }
`

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  ${media.tabMUp} {
    align-items: flex-start;
  }

  .input-wrapper {
    margin: 11px 0 0;

    ${media.tabMUp} {
      max-width: 440px;
      margin: 70px 0 -70px;
    }
  }

  .input-field {
    height: 30px;
    margin: 0;
    max-width: 260px;

    ${media.tabMUp} {
      height: 62px;
      max-width: 440px;
    }
  }

  .input-button {
    height: 30px;
    margin: 5px 0 0;
    padding: 0;
    max-width: 260px;
    
    ${media.tabMUp} {
      height: 54px;
      max-width: 440px;
      margin: 16px 0 0;
    }
  }

  .input-error {
    max-width: 295px;

    ${media.tabMUp} {
      max-width: 440px;
      margin: 8px 0;
    }
  }
` 