import styled from 'styled-components'
import { media } from '../../../styles/breakpoints'

export const ImageWrapper = styled.div`
  width: 100%;
  height: 59vw;
  overflow: hidden;

  ${media.tabMUp} {
    height: 700px;
  }

  img, picture, source {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: 100% 100%;

    ${media.deskGUp} {
      object-position: 70% 0;
    }

    @media only screen and (min-width: 1500px) {
      object-position: 0 20%;
    }
  }
`