import { SubscribeModalInput } from '../../atoms/SubscribeModalInput'
import LeadStore from '../../stores/LeadStore/LeadStore'
import { ComparativeBorderBottom, ComparativeBorderLeft, ComparativeBorderRight, ComparativeItem, ComparativeItemRight, ComparatveMainWrapper, MainTitle, MainWrapper, Text } from './PersonoSizeComparativeStyles'
const leadStore = new LeadStore()
export const PersonoSizeComparative = (props: { onClickButton: Function, isMobile: boolean }) => {
  const { isMobile } = props
  
  return (
    <>
      <MainWrapper data-testid="main-wrapper">
        <MainTitle> You won't even notice it </MainTitle>
        <Text> Small as a quarter! </Text>
        <SubscribeModalInput
          store={leadStore}
          inputFieldClassName="input-field"
          inputButtonClassName="input-button"
          inputWrapperClassName="input-wrapper"
          inputErrorClassName="input-error"
          openModalAfterSubscribe={props.onClickButton}
        />

        <ComparatveMainWrapper data-testid="comparative-main-wrapper">

          <ComparativeItem img={`/images/us_quarter_2d${isMobile ? '-mobile' : ''}.webp`}>
            <ComparativeBorderLeft data-testid="comparative-border-left">
              <div className="border">
                <div className="text">2 cm</div>
              </div>
            </ComparativeBorderLeft>
            <ComparativeBorderBottom data-testid="comparative-border-bottom-1">
              <div className="border">
                <div className="text">2 cm</div>
              </div>
            </ComparativeBorderBottom>
          </ComparativeItem>

          <ComparativeItemRight img={`/images/persono_sensor_2d${isMobile ? '-mobile' : ''}.webp`}>
            <ComparativeBorderRight data-testid="comparative-border-right">
              <div className="border">
                <div className="text">3 cm</div>
              </div>
            </ComparativeBorderRight>
            <ComparativeBorderBottom data-testid="comparative-border-bottom-2">
              <div className="border">
                <div className="text">2 cm</div>
              </div>
            </ComparativeBorderBottom>
          </ComparativeItemRight>

        </ComparatveMainWrapper>
      </MainWrapper>
    </>
  )
}
