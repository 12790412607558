import styled from 'styled-components'
import { media } from '../../../styles/breakpoints'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  overflow: hidden;
  justify-content: flex-start;
  align-items: center;
  max-width: 1440px;

  ${media.deskGUp} {
    flex-direction: row;
    overflow: hidden;
    width: 100%;
    min-height: 500px;
    padding: 20px 0 20px 50px;
  }
`

export const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 28px 23px 0;

  ${media.tabMUp} {
    padding: 28px;
  }

  ${media.deskGUp} {
    width: min(50vw, 720px);
  }

  @media screen and (min-width: 1440px) {
    padding-left: 46px;
  }

  p {
    margin: 0;
    text-align: center;
    font-size: 19px;
    font-weight: 400;

    ${media.tabMUp} {
      font-size: 38px;
    } 
    
    ${media.deskGUp} {
      text-align: start;
      font-size: 41px;
    }

    @media screen and (min-width: 1440px) {
      font-size: 62px;
    }
  }
`

export const ImageWrapper = styled.div<{backgroundUrl: string}>`
  background: url(${props => props.backgroundUrl});
  background-size: cover;
  height: 310px;
  width: 375px;

  ${media.tabMUp} {
    height: 400px;
    width: 500px;
  }

  ${media.deskGUp} {
    width: min(50vw, 500px);
    height: 500px;
    position: absolute;
    right: 0;
    background-position-x: 20px;
  }

  @media screen and (min-width: 1440px) {
    width: min(50vw,720px);
    height: 800px;
    position: absolute;
    right: 0;
    background-position-x: 0;
  }

  @media screen and (min-width: 1441px) {
    position: unset;
    height: 700px;
    background-position-x: -80px;
  }
`