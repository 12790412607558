import styled from 'styled-components'
import { media } from '../../../styles/breakpoints'

export const MainWrapper = styled.div`
  max-width: 1440px;
  margin: 0 auto;

  @media only screen and (min-width: 995px) {
    margin-bottom: 150px;
  }
`

export const MainTitle = styled.h3`
  width: 100%;
  text-align: center;
  color: #222;
  font-size: 27px;
  font-family: 'Proxima Nova', sans-serif;
  font-weight: 700;
  line-height: 120%;
  padding-top: 35px;
  margin: 0;

  @media screen and (min-width: 995px) {
    font-size: 50px;
    padding-top: 100px;
  }
`

export const MainSubtitle = styled.p`
  width: 100%;
  text-align: center;
  color: #222;
  font-size: 14px;
  font-family: 'Proxima Nova', sans-serif;
  font-weight: 400;
  line-height: 120%;
  margin: 14px 0 0;
  padding:0 20px 25px;

  @media screen and (min-width: 995px) {
    font-size: 30px;
    margin: 60px 0 0;
    padding-bottom: 77px;
  }
`

export const CardsWrapper = styled.div`
  width: 100%;
  background-color: #222;
  margin: 20px 0 0;
  display: flex;

  @media screen and (min-width: 995px) {
    background-color: transparent;
    max-width: 1135px;
    margin: 20px auto 0;
  }
`

export const ComparativeWrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 100%;
  margin-top: 20px;
`

export const TitleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 80%;
  margin: 0 auto;

  p {
    color: #f7f7f7;
    text-align: center;
    width: 100%;
    font-family: 'Proxima Nova', sans-serif;
    line-height: 120%;
  }

  .title {  
    font-size: 20px;
    font-weight: 700;
    line-height: 140%;
    margin: 0;
  }

  .subtitle {
    font-size: 12px;
    font-style: italic;
    margin: 0 0 20px;
  }
`

export const ItemWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: calc(100% - 30px);
  margin: 0 auto;
  border-bottom: 1px solid rgba(247, 247, 247, 0.3);
  
  @media screen and (min-width: 995px) {
    border-top: 1px solid #BBB;
    border-bottom: 0px;
    padding-left: 30px;
    z-index: 3;
  }

  &:last-of-type {
    border: none;

    @media screen and (min-width: 995px) {
      border-top: 1px solid #BBB;
    }
  }
`
export const ComparativeFeaturesWrapper = styled.div`
  width: 30vw;
  height: 20px;
  padding-right: 15px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  
  @media screen and (min-width: 995px) {
    min-width: 600px;
    max-width: 600px;
    justify-content: space-around;
  }

  .others {
    
    @media screen and (min-width: 425px) {
      margin-right: 10px;
    }
    
    @media screen and (min-width: 490px) {
      margin-right: 5vw;
    }

    @media screen and (min-width: 995px) {
      margin-right: 0;
    }
  }
`

export const ComparativeHasFeature = styled.div`
  width: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 10px;  
`

export const ComparativeTitleWrapper = styled(ComparativeHasFeature)`
  z-index: 3;

  @media screen and (min-width: 995px) {
    display: flex;
    flex-direction: column;
    width: 200px;
    justify-content: center;
    text-align: center;
    font-size: 24px; 
  }

  span {
    display: none;

    @media screen and (min-width: 995px) {
      display: initial;
      font-size: 14px;
      margin-bottom: 30px;
    }
  }
`

export const DifferecesTextContainer = styled(ItemWrapper)`
  border: none;
  font-size: 15px;
  color: #f7f7f7;
  margin-top: 12px;
  font-weight: 700;

  @media screen and (min-width: 995px) {
    color: #000;
    font-size: 18px;
  }

  .Persono {
    ${media.tabMUp}{
      color: #fff;
    }
  }
  .Differences {
    ${media.tabMUp}{
     margin-bottom: 12px;
     line-height: 40px;
    }
  }
`

const ComparativeBackground = styled.div`
  position: absolute;
`
export const ComparativeBackgroundPersono = styled(ComparativeBackground)`
  background-color: rgba(222,222,222,0.1);
  width: 80px;
  height: 785px;
  margin-left: -30px;
  margin-top: -10px;
  border-radius: 1px;
  overflow: hidden;

  @media only screen and (min-width: 492px) {
    height: 770px;
  }
  
  @media only screen and (min-width: 768px) {
    height: 785px;
  }
  
  @media screen and (min-width: 995px) {
    background-color: #222;
    width: 280px;
    margin-left: -280px;
    margin-top: -50px;
    height: 810px;
    box-shadow: 0px 6.39px 20.767px 0px rgba(0, 0, 0, 0.60);
    z-index: 1;
    border-radius: 13px;
  }
`

export const ComparativeBackgroundOthers = styled(ComparativeBackground)`
  display: none;
    
  @media screen and (min-width: 995px) {
    background-color: #fff;
    width: 280px;
    margin-left: 300px;
    margin-top: -50px;
    height: 810px;
    box-shadow: 0px 6.39px 20.767px 0px rgba(0, 0, 0, 0.60);
    z-index: 1;
    border-radius: 13px;
    display: initial;
  }
`

export const ListItem = styled.p`
  font-family: 'Proxima Nova', sans-serif;
  text-align: left;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 14px;
  max-width: 40vw;
  color: #f7f7f7;

  @media screen and (min-width: 995px) {
    color: #000;
    font-size: 20px;
    margin: 11px 0;
  }
`

export const IconWrapper = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
`

export const ComparativeIcon = styled.div`
  margin-top: -43px;
  min-height: 44px;
  min-width: 16px;
  border: solid 1px;
  border-color: #b0b0b0;
  border-radius: 70px;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media only screen and (min-width: 950px) {
    visibility: hidden;
  }
`

export const IconIndicator = styled.div`
  min-height: 10px;
  min-width: 3px;
  background-color: #FFF;
  border-radius: 70px;
  margin-top: 4px;

  @media only screen and (min-width: 950px) {
    visibility: hidden;
  }
`