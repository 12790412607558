import styled from 'styled-components'
import { media } from '../../../styles/breakpoints'

export const Background = styled.div`
  background:  linear-gradient(180deg, #000 0%, #1F4248 100%);
`

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  overflow: visible;
  justify-content: flex-start;
  align-items: center;
  padding: 35px 0 0;
  
  @media only screen and (min-width: 920px) {
    min-height: 720px;
    flex-direction: row;
    overflow: hidden;
    width: 100%;
    padding: 43px 0 0;
    align-items: flex-start;
    justify-content: space-between;
  }

  @media only screen and (min-width: 1242px) {
    min-height: 600px;
  }
`

export const ImageWrapper = styled.div`
  overflow: visible;
  padding: 0 30px 0;
  margin: 0;
  z-index: 1;

  img, source {
    object-fit: cover;
    width: 250px;
    min-height: 250px;
    max-height: 250px;
    
    ${media.tabMUp} {
      width: 300px;
      min-height: 300px;
      max-height: 300px;
    }

    @media only screen and (min-width: 920px) {
      position: absolute;
      right: 30px;
      width: 630px;
      min-height: 660px;
      max-height: 680px;
    }

    @media only screen and (min-width: 1250px) {
      min-height: 600px;
      max-height: 600px;
      position: initial;
    }
  }
`

export const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 20px;
  z-index: 2;
  
  @media only screen and (min-width: 920px) {
    padding: 0 0 0 90px;
    justify-content: left;
  }
`

export const HowDoesWorkText = styled.div`
  p {
    margin: 0;
    font-family: 'Proxima Nova', sans-serif;
  }

  .main-text {
    color: #f7f7f7;
    font-size: 16px;
    text-align: left;
    margin-bottom: 14px;
    
    @media only screen and (min-width: 920px) {
      max-width: 630px;
      font-size: 25px;
      margin-bottom: 40px;
    }
  }

  .coach {
    color: #4E98F1;
    font-size: 14px;
    text-align: left;
    margin-bottom: 16px;

    @media only screen and (min-width: 920px) {
      font-size: 23px;
      margin-bottom: 20px;
    }
  }

  .input-wrapper {
    margin: 0 auto;
    padding: 0;
    max-width: 215px;
    
    @media only screen and (min-width: 920px) {
      margin: 0;
      max-width: 465px;
    }
  }

  .input-button {
    height: 26px;

    @media only screen and (min-width: 920px) {
      height: 46px;
    }
  }
  .input-field {
    height: 26px;
    margin: 0;

    @media only screen and (min-width: 920px) {
      height: 46px;
    }
  }

`

export const HowDoesWorkTitle = styled.div`
  color: #fff;
  font-family: 'Proxima Nova', sans-serif;
  font-weight: 700;
  font-size: 20px;
  line-height: 120%;
  align-self: center;
  text-align: left;
  padding-bottom: 15px;

  @media only screen and (min-width: 920px) {
    max-width: 630px;
    font-size: 50px;
    padding: 0 0 50px 0;
    align-self: start;
    text-align: start;
  }
`