import { useTranslation } from 'next-i18next'
import { LocaleNamespaces } from '../../../../public/static/locale-namespaces.enum'
import { translateHtml } from '../../../helpers/translateHtml'
import { Theme } from '../../../styles/theme-enum'
import { WebpImage } from '../../atoms/Images'
import { LandingPageHeading, LandingPageParagraph } from '../../atoms/LandingPageTextWidgets'
import { ComponentWrapper, DottedDivider, ImageWrapper, MainWrapper, StepContainer, StepImage, StepInfo, StepsWrapper, StepTextWrapper, StepTitle, TextContainer, TitleImageContainer } from './AppStepsStyles'

export const AppSteps = (props: { isMobile: boolean }) => {
  const translateSetps = (key: string) => translateHtml(key, LocaleNamespaces.INTERNATIONAL_LANDING_PAGE)
  const { t: translate } = useTranslation(LocaleNamespaces.INTERNATIONAL_LANDING_PAGE)
  const { isMobile } = props

  const steps = [
    {
      title: translate('app-steps-step-1-title'),
      info: translate('app-steps-step-1-info'),
      image: '/images/buy_pillow',
    },
    {
      title: translate('app-steps-step-2-title'),
      info: <p dangerouslySetInnerHTML={translateSetps('app-steps-step-2-info')} />,
      image: '/images/buy_download',
    },
    {
      title: translate('app-steps-step-3-title'),
      info: translate('app-steps-step-3-info'),
      image: '/images/buy_persono_alarm',
    },
    {
      title: translate('app-steps-step-4-title'),
      info: translate('app-steps-step-4-info'),
      image: '/images/buy_zzz',
    },
    {
      title: translate('app-steps-step-5-title'),
      info: <p dangerouslySetInnerHTML={translateSetps('app-steps-step-5-info')} />,
      image: '/images/buy_cell_phone',
    }
  ]

  return (
    <MainWrapper>

      <TitleImageContainer>
        <TextContainer>
          <LandingPageHeading theme={Theme.default} >
            {translate('app-steps-title')}
          </LandingPageHeading>
          <div className="main-text">
            <LandingPageParagraph theme={Theme.light} >
              {translate('app-steps-text')}
            </LandingPageParagraph>
          </div>
        </TextContainer>

        <ImageWrapper data-testid="image-wrapper">
          <WebpImage
            src={`/images/buy_page_steps_image${isMobile ? '-mobile' : ''}.png`}
            srcSet={`/images/buy_page_steps_image${isMobile ? '-mobile' : ''}.webp`}
            alt="Simple, easy, and motivating"
            loadingStrategy="lazy"
          />
        </ImageWrapper>
      </TitleImageContainer>

      <StepsWrapper data-testid="steps-wrapper">

        {steps.map((step, index) => (
          <ComponentWrapper key={step.title}>
            {index != 0 &&
              <DottedDivider />
            }
            <StepContainer >
              <StepImage>
                <WebpImage
                  src={`${step.image}.png`}
                  srcSet={`${step.image}.webp`}
                  alt={step.title}
                  loadingStrategy="lazy"
                />
              </StepImage>
              <StepTextWrapper>
                <StepTitle>
                  <b>
                    {step.title}
                  </b>
                </StepTitle>
                <StepInfo>
                  {step.info}
                </StepInfo>
              </StepTextWrapper>
            </StepContainer>
          </ComponentWrapper>
        ))
        }

      </StepsWrapper>
    </MainWrapper>
  )
}