import styled from 'styled-components'
import { media } from '../../../styles/breakpoints'

export const TestimonialWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  background-color: #fff;
  padding: 0px 0;

  @media screen and (min-width: 1024px) {
    max-width: max-content;
  }
`

export const ImageContainer = styled.div<{isUnique: boolean}>`
  min-width: 60px;
  max-width: 60px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: start;
  margin: 0;

  ${media.tabMUp} {
    min-width: 94px;
    max-width: 94px;
  }

  @media screen and (min-width: 1024px) {
    min-width: 128px;
    max-width: 128px;
    width: 100%;
    margin: 0;
  }

  img {
    object-fit: contain;
  }
`

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0;
  margin-left: 8px;
  width: 100%;
  
  ${media.tabMUp} {
    margin-left: 15px;
  }
  
  @media screen and (min-width: 1024px) {
    margin-left: 50px;
  }

  .mainText { 
    color: #626271;
    font-size: 11px;
    font-family: 'Proxima Nova', sans-serif;
    font-weight: 300;
    line-height: 130%;
    margin: 0 0 10px;

    ${media.tabMUp} {
      font-size: 18px;
    }

    @media screen and (min-width: 1024px) {
      margin: 0;
      font-size: 33px;
    }
  }

  .customerText {
    color: #4E98F1;
    font-size: 11px;
    font-family: 'Proxima Nova', sans-serif;
    font-weight: 300;
    line-height: 15px;
    margin: 0;

    ${media.tabMUp} {
      font-size: 14px;
    }

    @media screen and (min-width: 1024px) {
      font-size: 26px;
      line-height: 26px;
      margin-top: 25px;
    }
  }

  span {
    color: #4E98F1;
    font-size: 11px;
    font-family: 'Proxima Nova', sans-serif;
    font-weight: 600;
    line-height: 15px;

    ${media.tabMUp} {
      font-size: 14px;
    }
    
    @media screen and (min-width: 1024px) {
      font-size: 26px;
    }
  }
`