import styled from 'styled-components'

export const LPSection = styled.section<{ background: string, height: number }>`
  background: ${({ background }) => background};
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 100%;
  height: ${({ height }) => height}px;
  z-index: 1;
`

export const LPGradient = styled.div<{ background: string, height: number }>`
  background: ${({ background }) => background};
  height: ${({ height }) => height}px;
  z-index: 1;
  
  * > {
    z-index: 2;
    opacity: 1;
  }
`