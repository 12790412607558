import styled from 'styled-components'
import { media } from '../../../styles/breakpoints'

export const MainWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: #3B79C5;
  padding: 59px 20px;

  ${media.tabMUp} {
    padding: 90px 0 50px 0;
  }

  .input-wrapper {
    margin: 0 auto;
    padding: 0;
    max-width: 215px;
    
    ${media.tabMUp} {
      margin: 28px auto 0;
      max-width: 465px;
    }
  }

  .input-button {
    height: 26px;

    ${media.tabMUp} {
      height: 46px;
    }
  }
  .input-field {
    height: 26px;
    margin: 0;

    ${media.tabMUp} {
      height: 46px;
    }
  }
`

export const Title = styled.h2`
  text-align: center;
  color: #f7f7f7;
  font-size: 22px;
  line-height: 11px;
  font-weight: 400;
  letter-spacing: 2px;
  text-transform: uppercase;
  margin-bottom: 50px;
  line-height: 1.2;

  ${media.tabMUp}{
    font-size: 33px;
    letter-spacing: 3px;
    margin-bottom: 68px;
  }
`