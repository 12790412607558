import { useTranslation } from 'next-i18next'
import { LocaleNamespaces } from '../../../../public/static/locale-namespaces.enum'
import { translateHtml } from '../../../helpers/translateHtml'
import { Theme } from '../../../styles/theme-enum'
import { LandingPageHeading, LandingPageParagraph } from '../../atoms/LandingPageTextWidgets'
import { Spacer } from '../../atoms/Spacer'
import { ComfortContentWrapper, CustomWrapper, IconWrapper, MainWrapper, Wrapper } from './ComfortContentStyles'
import { WomanOnTheBeach } from './WomanOnTheBeach'

export const ComfortContent = (props: { canUseWebP: boolean, isMobile: boolean }) => {
  const { t: translate } = useTranslation(LocaleNamespaces.INTERNATIONAL_LANDING_PAGE)
  const translatePrivacyHtml = (key: string) => translateHtml(key, 'international-landing-page')

  const { canUseWebP, isMobile } = props
  
  const webpImages = [
    '/images/buy_bluetooth_blue.webp',
    '/images/buy_no_watch.webp',
    '/images/buy_recharge.webp'
  ]

  const pngImages = [
    '/images/buy_bluetooth_blue.png',
    '/images/buy_no_watch.png',
    '/images/buy_recharge.png'
  ]

  return (
    <MainWrapper data-testid="comfort-main-wrapper">
      <ComfortContentWrapper>
        <LandingPageHeading 
          dataTestId="heading-wrapper"
          theme={Theme.default}
          textAlign={{ desktopValue: 'center', mobileValue: 'left' }}
        >
          {translate('comfort-content-title')}
        </LandingPageHeading>

        <Wrapper data-testid="paragraph-wrapper">
          <LandingPageParagraph
            theme={Theme.light}
            textAlign={{ desktopValue: 'center', mobileValue: 'left' }}
          > 
            {translate('comfort-content-text')}
          </LandingPageParagraph>
        </Wrapper>

        <Spacer mobileHeight="24px" desktopHeight="68px" data-testid="spacer-widget"/>

        <CustomWrapper data-testid="custom-wrapper">
          <IconWrapper data-testid="icon-wrapper-0">
            <img src={canUseWebP ? webpImages[0] : pngImages[0]} alt="Wireless data transmission" loading="lazy"/>
            <p>{translate('comfort-content-wireless')}</p>
          </IconWrapper>
          <IconWrapper data-testid="icon-wrapper-1">
            <img src={canUseWebP ? webpImages[1] : pngImages[1]} alt="Nothing to wear, nothing to charge" loading="lazy"/>
            <p>{translate('comfort-content-nothing-to-wear')}</p>
          </IconWrapper>
          <IconWrapper data-testid="icon-wrapper-2">
          <img src={canUseWebP ? webpImages[2] : pngImages[2]} alt="Battery that lasts the lifespan of the pillow (2 years)" loading="lazy"/>
            <p dangerouslySetInnerHTML={translatePrivacyHtml('comfort-content-no-need-to-recharge')} />
          </IconWrapper>
        </CustomWrapper>
      </ComfortContentWrapper>
      <WomanOnTheBeach isMobile={isMobile}/>
    </MainWrapper>
  )
}