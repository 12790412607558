import { useTranslation } from 'next-i18next'
import { useState } from 'react'
import { EffectCoverflow, Navigation, Pagination, } from 'swiper'
import 'swiper/css'
import 'swiper/css/effect-coverflow'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import { Swiper, SwiperSlide } from 'swiper/react'
import { LocaleNamespaces } from '../../../../public/static/locale-namespaces.enum'
import { WebpImage } from '../../atoms/Images'
import { appInfosSliderData } from './AppInfosSliderData'
import {
  BoldSliderText,
  ImageWrapper,
  SliderPage,
  SliderText,
  SliderTitle,
  SliderWrapper,
  TextWrapper
} from './AppInfosSliderStyles'

export const AppInfosSliderMobile = () => {
  const { t: translate } = useTranslation(LocaleNamespaces.INTERNATIONAL_LANDING_PAGE)

  const [currentSlide, setCurrentSlide] = useState<number>(1)

  const renderItens = () => {
    return appInfosSliderData.map((value, index) => {
      return (
        <SwiperSlide key={`key-${index}-`}>
          <SliderPage>
            <SliderText currentSlide={currentSlide == index}>
              <BoldSliderText>
                {translate(value.infoBold)}
              </BoldSliderText>
              {translate(value.info)}
            </SliderText>

            <ImageWrapper currentSlide={currentSlide == index}>
              <WebpImage
                src={`${value.image}.png`}
                srcSet={`${value.image}.webp`}
                alt={translate(value.info) + ',' + translate(value.infoBold)}
                loadingStrategy="lazy"
              />
            </ImageWrapper>
          </SliderPage>
        </SwiperSlide >
      )
    })
  }

  return (
    <div>
      <SliderTitle>
        {translate(appInfosSliderData[0].title)}
      </SliderTitle>
      <SliderWrapper>
        <Swiper
          effect={'coverflow'}
          centeredSlides={true}
          breakpoints={{
            320: {
              slidesPerView: 1.15,
              spaceBetween: 10,
              coverflowEffect: {
                stretch: 55,
                depth: 30,
              }
            },
            375: {
              slidesPerView: 1.35,
              spaceBetween: 10,
              coverflowEffect: {
                stretch: 55,
                depth: 30,
              }
            },
            500: {
              slidesPerView: 1.65,
              spaceBetween: 10,
              coverflowEffect: {
                stretch: 55,
                depth: 30,
              }
            }
          }}
          loop={false}
          coverflowEffect={{
            rotate: 0,
            modifier: 1,
            slideShadows: false,
            scale: 1,
          }}
          pagination={{
            clickable: false,
            enabled: true,
            type: 'bullets',
          }}
          navigation={true}
          modules={[EffectCoverflow, Pagination, Navigation]}
          initialSlide={1}
          className="carousel"
          onSlideChange={e => setCurrentSlide(e.activeIndex)}
        >
          {renderItens()}
        </Swiper>
      </SliderWrapper>
    </div>
  )
}

export const AppInfosSliderDesktop = () => {
  const { t: translate } = useTranslation(LocaleNamespaces.INTERNATIONAL_LANDING_PAGE)

  const [currentSlide, setCurrentSlide] = useState<number>(0)

  const renderItens = () => {
    return appInfosSliderData.map((value, index) => {
      return (
        <SwiperSlide key={`key-${index}-`}>
          <SliderPage>
            <ImageWrapper currentSlide={currentSlide == index}>
              <WebpImage
                src={`${value.image}.png`}
                srcSet={`${value.image}.webp`}
                alt={translate(value.info) + ',' + translate(value.infoBold)}
                loadingStrategy="lazy"
              />
            </ImageWrapper>

            <TextWrapper>
              <SliderTitle>
                {translate(appInfosSliderData[index].title)}
              </SliderTitle>
              <SliderText currentSlide={currentSlide == index}>
                <BoldSliderText visible={currentSlide == 0}>
                  {translate(value.infoBold)}
                </BoldSliderText>
                {translate(value.info)}
              </SliderText>
            </TextWrapper>
          </SliderPage>
        </SwiperSlide >
      )
    })
  }

  return (
    <div>
      <SliderWrapper>
        <Swiper
          spaceBetween={10}
          loop={false}
          pagination={{
            clickable: false,
            enabled: true,
            type: 'bullets',
          }}
          navigation={true}
          modules={[Pagination, Navigation]}
          initialSlide={0}
          className="carousel"
          onSlideChange={e => setCurrentSlide(e.activeIndex)}
        >
          {renderItens()}
        </Swiper>
      </SliderWrapper>
    </div>
  )
}
