import { WebpImage } from '../../atoms/Images'
import { ImageContainer, ImagesWrapper, Wrapper } from './ImageBoxStyles'

export const ImageBox = (props: { isMobile: boolean }) => {
  const imgSrc1 = `/images/buy_page_box-01${props.isMobile ? '-mobile' : ''}`
  const imgSrc2 = `/images/buy_page_box-02${props.isMobile ? '-mobile' : ''}`
  const imgSrc3 = `/images/buy_page_box-03${props.isMobile ? '-mobile' : ''}`

  return (
    <Wrapper data-testid="image-box-wrapper">
      <ImagesWrapper data-testid="images-wrapper">
        <ImageContainer data-testid="first-image-container">
          <WebpImage
            src={`${imgSrc1}.png`}
            srcSet={`${imgSrc1}.webp`}
            alt="Persono Pillow 3 inner layers"
            loadingStrategy="lazy"
          />
        </ImageContainer>

        <ImageContainer data-testid="second-image-container">
          <WebpImage
            src={`${imgSrc2}.png`}
            srcSet={`${imgSrc2}.webp`}
            alt="Persono Pillow softness adjustment"
            className="mobile-large-row"
            loadingStrategy="lazy"
          />
          <WebpImage
            src={`${imgSrc3}.png`}
            srcSet={`${imgSrc3}.webp`}
            alt="Persono Pillow height adjustment"
            className="mobile-small-row"
            loadingStrategy="lazy"
          />
        </ImageContainer>

        <ImageContainer data-testid="responsive-image-container">
          <WebpImage
            src={`${imgSrc3}.png`}
            srcSet={`${imgSrc3}.webp`}
            alt="Persono Pillow height adjustment"
            className="desktop-responsive-column"
            loadingStrategy="lazy"
          />
        </ImageContainer>

      </ImagesWrapper>
    </Wrapper>
  )
}