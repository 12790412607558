import styled from 'styled-components'
import { media } from '../../../styles/breakpoints'

export const Wrapper = styled.div`
  width: 100%;
  height: 320px;
  
  ${media.tabMUp} {
    height: 620px;
  }
`

export const Container = styled.div`
  position: relative;  
  overflow: visible;
  justify-content: center;
  align-items: center;
  width: max-content;
  box-shadow: 0px 2.27788px 25.0567px rgba(0, 0, 0, 0.2);
  background-color: #fff;
  border-radius: 87px;
  margin: -45px auto 0; 
  
  font-family: 'Proxima Nova', sans-serif;
  color: #4E98F1;
  padding: 0 15px;
  font-size: 12px;
  font-weight: 400;
  line-height: 25px;

  ${media.tabMUp} {
    margin-top: -100px;
    padding: 0 35px;
    font-size: 28px;
    line-height: 57px;
  }
`

export const ImagesWrapper = styled.div`
  height: 100%;
  display: grid;
  grid-template-columns: 1.3fr 1fr;

  ${media.tabMUp} {
    grid-template-columns: 1.3fr 1fr 1.3fr;
  }
`

export const ImageContainer = styled.div`
  width: 100%;
  height: 320px;
  display: grid;
  grid-template-columns: 1fr;
  overflow: hidden;
  
  ${media.tabMUp} {
    display: unset;
    height: 620px;
  }

  .mobile-large-row {
    height: 180px;

    ${media.tabMUp} {
      height: 100%;
    }
  }

  .mobile-small-row {
    height: 140px;

    ${media.tabMUp} {
      display: none;
      visibility: hidden;
    }
  }

  .desktop-responsive-column {
    display: none;
    visibility: hidden;

    ${media.tabMUp} {
      display: unset;
      visibility: visible;
    }
  }

  img, picture, source {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: 0% 100%;
    
    ${media.mobLUp} {
      object-position: 0% 75%;
    }

    ${media.tabMUp} {
      object-position: 10% 60%;
    }

    ${media.deskGUp} {
      object-position: 20% 60%;
    }
  }
`