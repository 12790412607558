import { useTranslation } from 'next-i18next'
import { LocaleNamespaces } from '../../../../public/static/locale-namespaces.enum'
import { ComparativeNoIcon } from '../../atoms/icons/ComparativeNoIcon'
import { ComparativeYesIcon } from '../../atoms/icons/ComparativeYesIcon'
import {
  CardsWrapper,
  ComparativeBackgroundOthers,
  ComparativeBackgroundPersono,
  ComparativeFeaturesWrapper,
  ComparativeHasFeature,
  ComparativeIcon,
  ComparativeTitleWrapper,
  ComparativeWrapper,
  DifferecesTextContainer,
  IconIndicator,
  IconWrapper,
  ItemWrapper,
  ListItem,
  MainSubtitle,
  MainTitle,
  MainWrapper,
} from './ComparativeCardsStyles'

interface IComparativeCards {
  name: string,
  personoHasFeature: boolean,
  otherHasFeature: boolean
}

export const ComparativeCard = () => {
  const { t: translate } = useTranslation(LocaleNamespaces.INTERNATIONAL_LANDING_PAGE)

  const features: IComparativeCards[] = [
    {
      name: translate('comparative-cards-comparison-1'),
      personoHasFeature: true,
      otherHasFeature: false
    },
    {
      name: translate('comparative-cards-comparison-2'),
      personoHasFeature: true,
      otherHasFeature: false
    },
    {
      name: translate('comparative-cards-comparison-3'),
      personoHasFeature: true,
      otherHasFeature: false
    },
    {
      name: translate('comparative-cards-comparison-4'),
      personoHasFeature: true,
      otherHasFeature: false
    },
    {
      name: translate('comparative-cards-comparison-5'),
      personoHasFeature: true,
      otherHasFeature: false
    },
    {
      name: translate('comparative-cards-comparison-6'),
      personoHasFeature: true,
      otherHasFeature: false
    },
    {
      name: translate('comparative-cards-comparison-7'),
      personoHasFeature: true,
      otherHasFeature: true
    },
    {
      name: translate('comparative-cards-comparison-8'),
      personoHasFeature: true,
      otherHasFeature: true
    },
    {
      name: translate('comparative-cards-comparison-9'),
      personoHasFeature: true,
      otherHasFeature: true
    },
    {
      name: translate('comparative-cards-comparison-10'),
      personoHasFeature: true,
      otherHasFeature: true
    },
    {
      name: translate('comparative-cards-comparison-11'),
      personoHasFeature: true,
      otherHasFeature: true
    },
    {
      name: translate('comparative-cards-comparison-12'),
      personoHasFeature: false,
      otherHasFeature: true
    },
    {
      name: translate('comparative-cards-comparison-13'),
      personoHasFeature: false,
      otherHasFeature: true
    },
    {
      name: translate('comparative-cards-comparison-14'),
      personoHasFeature: true,
      otherHasFeature: true
    },
    {
      name: translate('comparative-cards-comparison-15'),
      personoHasFeature: true,
      otherHasFeature: true
    },
    {
      name: translate('comparative-cards-comparison-16'),
      personoHasFeature: true,
      otherHasFeature: false
    },
    {
      name: translate('comparative-cards-comparison-17'),
      personoHasFeature: true,
      otherHasFeature: false
    },
    {
      name: translate('comparative-cards-comparison-18'),
      personoHasFeature: true,
      otherHasFeature: true
    },
    {
      name: translate('comparative-cards-comparison-19'),
      personoHasFeature: true,
      otherHasFeature: true
    }
  ]

  return (
    <MainWrapper>
      <MainTitle>
        {translate('comparative-cards-title')}
      </MainTitle>
      <MainSubtitle>
        {translate('comparative-cards-subtitle')}
      </MainSubtitle>

      <CardsWrapper data-testid="cards-wrapper">
        <ComparativeTable items={features} />
      </CardsWrapper>
    </MainWrapper>
  )
}

const ComparativeTable = (props: { items: IComparativeCards[] }) => {
  const { t: translate } = useTranslation(LocaleNamespaces.INTERNATIONAL_LANDING_PAGE)

  const { items } = props

  return (
    <ComparativeWrapper>
      <IconWrapper>
        <ComparativeIcon>
          <IconIndicator />
        </ComparativeIcon>
      </IconWrapper>

      <DifferecesTextContainer>
        <div className="Differences">
          {translate('comparative-cards-differences-title')}
        </div>

        <ComparativeFeaturesWrapper>
          <ComparativeBackgroundPersono/>

          <ComparativeTitleWrapper className="Persono">
            {translate('comparative-cards-persono-title')}
            <span className="Persono">
              {translate('comparative-cards-persono-subtitle')}
            </span>
          </ComparativeTitleWrapper>

          <ComparativeBackgroundOthers/>
          <ComparativeTitleWrapper className="others">
            {translate('comparative-cards-others-title')}
            <span>
              {translate('comparative-cards-others-subtitle')}
            </span>
          </ComparativeTitleWrapper>

        </ComparativeFeaturesWrapper>

      </DifferecesTextContainer>

      {items.map((item, index) =>

        <ItemWrapper key={index}>
          <ListItem>
            {item.name}
          </ListItem>
          <ComparativeFeaturesWrapper>
            <ComparativeHasFeature>
              {item.personoHasFeature
                ? <ComparativeYesIcon />
                : <ComparativeNoIcon />
              }
            </ComparativeHasFeature>
            <ComparativeHasFeature className="others">
              {item.otherHasFeature
                ? <ComparativeYesIcon />
                : <ComparativeNoIcon />
              }
            </ComparativeHasFeature>
          </ComparativeFeaturesWrapper>

        </ItemWrapper>
      )}

    </ComparativeWrapper>
  )
}