import { captureException } from '@sentry/nextjs'
import { useTranslation } from 'next-i18next'
import { useEffect, useState } from 'react'
import { LocaleNamespaces } from '../../../../public/static/locale-namespaces.enum'
import { getRemoteConfigValue, RemoteConfigKeys } from '../../../services/firebase/RemoteConfig'
import { Theme } from '../../../styles/theme-enum'
import { LandingPageHeading, LandingPageParagraph } from '../../atoms/LandingPageTextWidgets'
import { SubscribeModalInput } from '../../atoms/SubscribeModalInput'
import LeadStore from '../../stores/LeadStore/LeadStore'
import { MainWrapper, Title } from './CallToActionStyles'

const leadStore = new LeadStore()

export const CallToAction = (props: { onClickButton: Function }) => {
  const { t: translate } = useTranslation(LocaleNamespaces.INTERNATIONAL_LANDING_PAGE)
  const [startCampaignDate, setStartCampaignDate] = useState<string>('')

  useEffect(() => {
    getStartCampaignDateFromRemoteConfig()
  }, [])

  const getStartCampaignDateFromRemoteConfig = async () => {
    try {
      const remoteStartCampaignDate = await getRemoteConfigValue<string>(RemoteConfigKeys.LPI_START_CAMPAIGN_DATE_BANNER)
      if (remoteStartCampaignDate) {
        setStartCampaignDate(remoteStartCampaignDate)
      }
    } catch (e) {
      captureException(e)
    }
  }

  return (
    <MainWrapper data-testid="call-to-action-main-wrapper">
      <Title>{translate('call-to-action-title').replace('START_CAMPAIGN_DATE', startCampaignDate)}</Title>
      <LandingPageHeading theme={Theme.dark}>{translate('call-to-action-heading')}</LandingPageHeading>
      <LandingPageParagraph theme={Theme.dark}>{translate('call-to-action-paragraph')}</LandingPageParagraph>
      <SubscribeModalInput
        store={leadStore}
        inputFieldClassName="input-field"
        inputButtonClassName="input-button"
        inputWrapperClassName="input-wrapper"
        inputErrorClassName="input-error"
        openModalAfterSubscribe={props.onClickButton}
        isprimaryColor={false}
      />
    </MainWrapper>
  )
}