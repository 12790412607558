import { useTranslation } from 'next-i18next'
import { useState } from 'react'
import { Navigation, Pagination, } from 'swiper'
import 'swiper/css'
import 'swiper/css/effect-coverflow'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import { Swiper, SwiperSlide } from 'swiper/react'
import { LocaleNamespaces } from '../../../../public/static/locale-namespaces.enum'
import { InternationalTestimonial } from './InternationalTestimonial'
import { SliderPageWrapper, SliderWrapper, TestimonialItem } from './TestimonialSliderStyles'

export const TestimonialSlider = () => {
  const { t: translate } = useTranslation(LocaleNamespaces.INTERNATIONAL_LANDING_PAGE)
  const [currentSlide, setCurrentSlide] = useState<number>(0)

  const testimonials = [
    {
      name: translate('testimonial-section-testimonial-1-name'),
      occupation: translate('testimonial-section-testimonial-1-occupation'),
      speech: translate('testimonial-section-testimonial-1-speech'),
      img: 'lp_testimonial_leonel'
    },
    {
      name: translate('testimonial-section-testimonial-2-name'),
      occupation: translate('testimonial-section-testimonial-2-occupation'),
      speech: translate('testimonial-section-testimonial-2-speech'),
      img: 'lp_testimonial_vitor'
    },
    {
      name: translate('testimonial-section-testimonial-5-name'),
      occupation: translate('testimonial-section-testimonial-5-occupation'),
      speech: translate('testimonial-section-testimonial-5-speech'),
      img: 'lp_testimonial_helio'
    },
    {
      name: translate('testimonial-section-testimonial-3-name'),
      occupation: translate('testimonial-section-testimonial-3-occupation'),
      speech: translate('testimonial-section-testimonial-3-speech'),
      img: 'lp_testimonial_alexandre'
    },
    {
      name: translate('testimonial-section-testimonial-4-name'),
      occupation: translate('testimonial-section-testimonial-4-occupation'),
      speech: translate('testimonial-section-testimonial-4-speech'),
      img: 'lp_testimonial_adriano'
    }
  ]

  const renderItens = () => {
    return testimonials.map((testimonial, index) => {
      return (
        <SwiperSlide key={`key-${index}`}>
          <SliderPageWrapper>
            <TestimonialItem data-testid={`${testimonial.name.toLowerCase()}-testimonial`} key={testimonial.name}>
              <InternationalTestimonial
                name={testimonial.name}
                occupation={testimonial.occupation}
                speech={testimonial.speech}
                img={testimonial.img}
                isUnique={false}
                customMargin={currentSlide == 2 || currentSlide == 5}/>
            </TestimonialItem>
          </SliderPageWrapper>
        </SwiperSlide >
      )
    })
  }

  return (
    <SliderWrapper data-testid="main-wrapper">
      <Swiper
        centeredSlides={true}
        loop={true}
        pagination={{
          clickable: false,
          enabled: true,
        }}
        navigation={true}
        modules={[Pagination, Navigation]}
        initialSlide={0}
        className="carousel"
        onSlideChange={e => setCurrentSlide(e.activeIndex)}
      >
        {renderItens()}
      </Swiper>
    </SliderWrapper>
  )
}