import styled, { css } from 'styled-components'
import { media } from '../../../styles/breakpoints'
import { Theme } from '../../../styles/theme-enum'

export interface ITextWidgetsProps {
  theme: Theme,
  desktopTextAlign?: string,
  mobileTextAlign?: string,
  boldText?: boolean
}

const headingThemes = {
  [Theme.default]: css`
    color: #4E98F1;
  `,
  [Theme.dark]: css`
    color: #f7f7f7;
  `,
  [Theme.light]: css`
    color: #222;
  `
}

const paragraphThemes = {
  [Theme.default]: css`
    color: #4E98F1;
  `,
  [Theme.dark]: css`
    color: #f7f7f7;
  `,
  [Theme.light]: css`
    color: #222;
  `
}

export const InternationalH2 = styled.h2<ITextWidgetsProps>`
  font-family: 'Proxima Nova', sans-serif;
  ${props => headingThemes[props.theme]};
  margin: 0;
  font-size: 26px;
  font-weight: ${({boldText}) => boldText ? '700' : '400'};
  text-align: ${({mobileTextAlign}) => mobileTextAlign != '' ? mobileTextAlign : 'center'};

  ${media.tabMUp} {
    text-align: ${({desktopTextAlign}) => desktopTextAlign != '' ? desktopTextAlign : 'center'};
    font-size: 50px;
  }
`

export const InternationalP = styled.p<ITextWidgetsProps>`
  font-family: 'Proxima Nova', sans-serif;
  ${props => paragraphThemes[props.theme]};
  text-align: ${({mobileTextAlign}) => mobileTextAlign != '' ? mobileTextAlign : 'center'};
  font-weight: ${({boldText}) => boldText ? '700' : '400'};
  font-size: 14px;
  line-height: 19px;

  ${media.tabMUp} {
    font-size: 27px;
    line-height: 37px;
    text-align: ${({desktopTextAlign}) => desktopTextAlign != '' ? desktopTextAlign : 'center'};
  }
`

export const InternationalTestimonial = styled.div<ITextWidgetsProps>`
  font-family: 'Proxima Nova', sans-serif;
  ${props => paragraphThemes[props.theme]};
  text-align: ${({mobileTextAlign}) => mobileTextAlign != '' ? mobileTextAlign : 'center'};
  color: #626271;
  font-weight: 300;
  font-size: 12px;
  line-height: 15px;

  span {
    color: #303030;
    font-weight: 400;
  }

  ${media.tabMUp} {
    font-size: 19px;
    line-height: 24px;
  }

  ${media.deskGUp} {
    font-size: 25px;
    line-height: 33px;
  }
`

export const InternationalTestimonialAuthor = styled.div<ITextWidgetsProps>`
  font-family: 'Proxima Nova', sans-serif;
  ${props => paragraphThemes[props.theme]};
  text-align: ${({mobileTextAlign}) => mobileTextAlign != '' ? mobileTextAlign : 'center'};
  color: #4E98F1;
  font-weight: 300;
  font-size: 10px;
  margin: 0;

  span {
    font-weight: 600;
  }

  ${media.tabMUp} {
    font-size: 16px;
  }

  ${media.deskGUp} {
    font-size: 23px;
  }
`