import styled from 'styled-components'
import { media } from '../../../styles/breakpoints'

export const DropdownWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 80px 0;

  ${media.tabMUp} {
    padding: 100px 0;
  }
`

export const DropdownMainTitle = styled.h3`
  color: #4E98F1;
  font-family: 'Proxima Nova', sans-serif;
  font-weight: 700;
  line-height: 130%;
  margin-bottom: 30px;
  font-size: 26;
  text-align: center;
  padding: 0 20px;

  ${media.tabMUp} {
    font-size: 30px;
    margin-bottom: 50px;
  }
`