import styled from 'styled-components'
import { media } from '../../../styles/breakpoints'

export const MainWrapper = styled.div`
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
`

export const TitleImageContainer = styled.div`
  display: flex;
  flex-direction: column-reverse;

  ${media.deskGUp} {
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    margin: 0 auto;
  }
`

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 20px;

  ${media.deskGUp} {
    margin: 0 auto;
    padding: 100px 100px 0 50px;

    & > * {
      text-align: left;
    }

    p {
      font-size: 30px;
    }
  }

  .main-text {
    max-width: 600px;
    margin: 0 auto;
    padding-bottom: 30px;

    ${media.deskGUp} {
      padding-bottom: initial;
      & > * {
        text-align: left;
      }
    }
  }
`

export const ImageWrapper = styled.div`
  max-height: 330px;
  max-width: 330px;
  margin: -135px auto 0;

  ${media.deskGUp} {
    max-width: 550px;
    max-height: 550px;
    margin: -160px auto 0;
  }

  & > img, source, picture, * {
    max-height: 330px;
    max-width: 330px;
    aspect-ratio: 1/1;
    object-position: top 0 left 20px;
    object-fit: contain;

    ${media.deskGUp} {
      min-width: 550px;
      min-height: 550px;
      max-width: 550px;
      max-height: 550px;
      object-position: unset;
    }
  }
`

export const StepsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 64px;

  .middle-dot {
    display: none;
    max-width: 0;
  }

  ${media.deskGUp} {
    align-items: start;
    padding: 32px 30px 89px;
  }

  ${media.deskGMUp} {
    flex-direction: row;
    padding: 0 20px 130px;
    max-width: 100%;
    align-items: flex-start;
    margin: 0;

    .middle-dot {
      display: initial;
      max-width: initial;
    }
  }
`
export const StepContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 20px;

  ${media.deskGUp} {
    height: 100%;
    flex-direction: row;
    justify-content: flex-start;
  }
  
  ${media.deskGMUp}{
    flex-direction: column;
  }
`

export const StepImage = styled.div`
  max-height: 65px;
  max-width: 65px;
  
  & > img, source, picture, * {
    height: 65px;
    width: 65px;
    object-fit: contain;
  }
`

export const StepTitle = styled.p`
  color: #222;
  font-weight: 700 !important;
  font-family: 'Proxima Nova', sans-serif;
  font-size: 14px;
  line-height: 150%;
  margin: 0;

  ${media.tabMUp} {
    font-size: 18px;
  }

  ${media.deskGUp} {
    padding-bottom: 15px;
  }

  ${media.deskGMUp} {
    padding-bottom: 0;
  }
`
export const StepInfo = styled.div`
  color: #222;
  font-weight: 400;
  font-family: 'Proxima Nova', sans-serif;
  font-size: 14px;
  line-height: 150%;
  text-align: center;
  max-width: 205px;
  margin: 0;

  ${media.tabMUp} {
    font-size: 18px;
    max-width: 270px;
  }

  ${media.deskGUp} {
    text-align: start;
    max-width: 100%;
  }

  ${media.deskGMUp} {
    text-align: center;
    max-width: 270px;
  }
`

export const ComponentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  ${media.deskGUp} {
    flex-direction: row;
    align-items: flex-start;
  }
`

export const DottedDivider = styled.div`
  margin: 0;
  height: 48px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  ${media.deskGUp} {
    display: none;
    width: 0;
  }

  ${media.deskGMUp} {
    display: flex;
    margin: 30px 0;
    height: 20px;
    min-width: 87px;
    flex-direction: row;
    align-items: center;
  }
`

export const StepTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  ${media.deskGUp} {
    align-items: start;
    padding: 20px 32px;
  }
  ${media.deskGMUp} {
    flex-direction: column;
    align-items: center;
    padding: 0;
  }
`